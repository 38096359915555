#modal--focus .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

#modal--focus .modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

#modal--focus .modal {
	z-index: 100;
	background: white;
	position: relative;
	margin: 3.5rem auto auto auto;
	border-radius: 3px;
	max-width: 900px;
	padding: 2rem;
	display: block!important;
}

#modal--focus .modal-header {
  display: flex;
  justify-content: flex-end;
}

#modal--focus .modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
}
#modal--focus .modal-close-button span {
	font-size: 30px;
}

#modal--focus button {
  /* font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem; */
}

#modal--focus .button-default {
  background: #247BA0;
  color: #fff;
}

