.a-input-select__list.is-required {
  border: solid 1px #E56C5E;
  /* background: #FFDAD6; */
}
.m-pager-title {
  position: fixed;
  top: 130px;
  width: 100%;
  z-index: 10;
}
.u-title {
  display: block;
  margin: 0 auto 50px;
  width: 100%;
  max-width: 750px;
}

.u-price{
  display: flex;
  align-items: center;
  justify-content: center;
}
.u-price .u-priceLeft {
  display: flex;
  align-items: baseline;
  margin-right: 20px;
}
.u-price .u-priceLeft .u-number {
  font-size: 35px;
  color: #007048;
}
.u-price .u-priceRight .u-prefix {
  font-size: 30px;
}
.u-price .u-priceRight {
  display: flex;
  align-items: baseline;
}

.u-price .u-priceRight .u-number {
  font-size: 20px;
  color: #007048;
}

.u-price .u-priceRight .u-prefix {
  font-size: 20px;
}
.u-price .u-priceLeft .u-title, .u-price .u-priceRight .u-title {
  margin-right: 10px;
}
.u-action {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 0px;
  margin-bottom: 50px;
}
.u-action a {
  width: 350px;
}
.u-action .u-bg-green {
  background: #53AA32;
  box-shadow: 0 4px 0 0 #40981E;
}
.u-descripton {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 50px;
}
.u-item-exchange {
  display: block;
  margin: 0 auto 50px;
  width: 100%;
  max-width: 750px;
}
.u-item-exchange .o-exchange-item__box__title{
  font-size: 18px;
}
.t-slider-table {
  width: 100%;
  display: flex;
  margin-top: 50px;
}
.t-slider-table-margin-change {
  width: 100%;
  display: flex;
  margin-top: 10px;
}
.table-border {
  border-right: 1px solid #C4C4C4;
  border-left: 1px solid #C4C4C4;
}
.t-u-table {
  border-radius: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.t-u-table .t-header {
  width: 100%;
  background: #40981E;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.t-u-table .t-header .t-header-left {
  display: flex;
  align-items: center;
}
.t-u-table .t-header .t-header-left .a-input-checkbox__label {
  color: #fff;
}
.t-u-table .t-header .t-header-right {
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  padding: 5px 10px;
  cursor: pointer;
}
.t-u-table .t-header .t-header-right .t-header-right-title {
  padding-top: 5px;
  padding-left: 5px;
}

.t-u-table .t-body {
  background: #fff;
  padding: 20px 50px;
  display: flex;
  width: 100%;
}

.t-u-table .t-body .m-table.m-table--keep-point{
  border: none;
}
.t-u-table .t-body .m-table__head {
  padding: 10px 0;
}
.t-u-table .t-body .m-table__head--keep-point-date.bold{
  font-weight: bolder;
}

.t-u-table .t-body .m-table__head--keep-point-date{
  background: #fff;
  border: none;
  font-size: 16px;
  width: unset;
  text-align: left;
}
.t-u-table .t-body .m-table__head--keep-point-date p {
  font-size: 16px;
}
.u-total {
  display: block;
  margin: 0 auto 50px;
  width: 100%;
  max-width: 350px;
}
.u-total .o-home__mv__buttons__button {
  margin: 0;
}
.u-total .u-fee {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.u-total .u-fee >div {
  font-size: 20px;
}
.u-total .u-fee .o-exchange-item__box__must-point__count{
  font-size: 25px;
}
.o-close__account_title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.o-close__account_reason {
  display: block;
  margin: 0 auto 50px;
  width: 100%;
  max-width: 300px;
}
.o-close__account_reason .o-section {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: flex-start;
}
.o-close-quote--accout.o-entry__notice__title {
  text-align: left;
  color: #333;
}
.o-close-quote--accout.o-entry__notice__title::before {
  display: none;
}
.o-close__footer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.o-close__footer > p {
  font-size: 16px;
  font-weight: bold;
}
.o-entry__password {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 1px dashed #007048;
  padding: 20px;
}
.o-entry__password > p {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.a-button--fillAutoAddress:disabled {
  background: #808080;
  box-shadow: 0 2px 0 0 #626262;
}
@media (max-width: 768px){
  .m-pager-title {
    top: 68px;
  }
  .o-mypage-header {
    padding: 40px 0 0!important;
  }
  .m-animal-header {
    padding-top: 50px!important;
  }
}
.list-verify-register {
  list-style: none;
  padding-left: 0;
}
.list-verify-register li {
  font-size: 16px;
  text-align: center;
}
.list-verify-register li:first-child  {
  margin-bottom: 20px;
}

.shipping-u .m-table__head--keep-point-date {
  background: #40981E;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.u--label-point-used {
  font-size: 18px;
  font-weight: 700;
}

.point-used-u .m-table__head--keep-point-date  {
  background: #E4EEDA;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  width: 40%;
}
.point-used-u .m-table--keep-point{
  border: solid 1px #40981e;
}
.point-used-u .table-border {
  border-right: 1px solid #40981e;
  border-left: 1px solid #40981e;
  border-bottom: 1px solid #40981e;
}
.point-used {
  text-align: left;
  padding-left: 50px;
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  color:#000000;
}
.u--couter-exchange {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 15px;
}
.u--couter-exchange .action {
  cursor: pointer;
}
.u--couter-exchange .c-number {
  font-weight: bolder;
}
.o-entry__delivery{
  background: #007048;
  padding: 10px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
.tranform--revert::after {
  transform: translateY(-50%) rotate(180deg)!important;
}
.w20px::after {
  width: 20px;
  height: 20px;
}

.o-exchange-item__box__image-frame__image {
  height: 100%;
  object-fit: fill;
}
.t-contents {
  padding: 110px 0 60px 0;
}
.o-mypage-header {
  padding: 110px 0 0;
}
.m-animal-header {
  padding-top: 110px;
}

.section--dynamic-exchange-completed{
  position: relative;
  width: 100%;
  height: 570px;
}
@media (max-width: 480px){
  .section--dynamic-exchange-completed{
    height: 300px;
  }
}
.section--dynamic-exchange-completed canvas{
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  background-position: center;
  background-size: contain;
}

@media (max-width: 780px) {
  #point-exchange .m-table__head {
    display: revert;
    width: 33%;
    padding: 5px 0;
    /*border:  1px solid #C4C4C4*/
  }
  .t-slider-table table {
    width: 100%;
  }
  .t-contents{
    padding-top: 60px;
  }
  .t-contents.t-contents--bg-ivory {
    padding-top: 60px;
  }

  #point-exchange .table-is-vertical{
    display: block;
    width: 100%;
  }
}
@media (max-width: 980px) {
  .t-contents.t-contents--bg-ivory {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.u-not-found {
  width: 100%;
  padding: 20px;
  height: 100%;
}
@media (max-width: 780px) {
  .u-not-found {
    background: #fff;
  }
  /* 2022.02.17追加 */
.point-used {
  padding:2px 10px;
}
}
.o-faq-list__item__body.is-open{
  display: block;
  transition: cubic-bezier();
}